<template>
  <div class="form-page position-relative user-activity-page" :class="{'p-2': $store.state.isMobile}">
    <div v-if="loading">
      <div class="loading-shade" ></div>
      <pre-loader></pre-loader>
    </div>
    <!-- Range select -->
    <div class="geoff-bg-section rounded-3 mb-2 p-n section-range-selector">
      <div>Select Range</div>
      <select v-model="selectedRange" class="d-flex fs-12 justify-content-between border-0 p-2 geoff-bg-active rounded-3">
        
        <!-- <option value="1" selected>Last 24 Hours</option> -->
        <option value="2">Last 2 Days</option>
        <option value="3">Last 5 Days</option>
        <option value="4">Last 7 Days</option>
        <option value="5">Last 14 Days</option>
        <option value="6">Last 30 Days</option>
        <option value="7">Last 2 Months</option>
        <option value="8">Last 3 Months</option>
        <option value="9">Last 6 Months</option>
        <!-- <option value="10">Last 9 Months</option>
        <option value="11">Last 12 Months</option> -->
      </select>
    </div>

    <!-- Win/loss -->
    <div v-if="winLossData" class="geoff-bg-section rounded-3 mb-2 p-n">
      <div class="tile-header">
        <div>
          <div>Win/Loss</div>
        </div>
      </div>
      <bar-chart class="py-3" :chart-data="winLossData" :show-negative="true"></bar-chart>
    </div>

    <!-- Net Deposit -->
    <div v-if="netDeposit" class="geoff-bg-section rounded-3 mb-2 p-n">
      <div class="tile-header">
        <div>
          <div>Net Deposits</div>
          <div class="note geoff-text-note"></div>
        </div>
        <div>
          <div class="label-price label-right">{{ CURRENCY.symbol }}{{ netDeposit?.summary }}</div>
          <div class="note geoff-text-note">{{ dateRangeString }}</div>
        </div>
      </div>

      <div class="net-deposit-content">
        <div>
          <div class="label-text">Total Deposit</div>
          <div class="label-price">{{ CURRENCY.symbol }}{{ netDeposit.depositTotalAmount }}</div>
        </div>
        <div>
          <div class="label-text">Total Withdrawals</div>
          <div class="label-price">{{ CURRENCY.symbol }}{{ netDeposit.withdrawalTotalAmount }}</div>
        </div>
      </div>
      

    </div>

    <!-- Deposit Limit -->
    <div v-if="depositLimit" class="geoff-bg-section rounded-3 mb-2 p-n">
      
      <div class="tile-header">
        <div>
          <div>Deposit Limit</div>
          <!-- <div class="note geoff-text-note"></div> -->
        </div>
        <div>
          <div><router-link class="geoff-link-accent text-decoration-none" :to="{name:'updateDepositLimit'}">Change</router-link></div>
          <!-- <div class="note geoff-text-note"></div> -->
        </div>
      </div>

      <div v-if="depositLimit.isUnlimited" class="deposit-limit-content">
        <div class="label-text">No Limits</div>
      </div>

      <div v-else class="deposit-limit-content">
        <template v-for="(item, idx) in depositLimit.limits" :key="idx">
          <div v-if="item.limit > 0">
            <div class="label-text">{{ item.period }}</div>
            <div class="label-price">{{ CURRENCY.symbol }}{{ item.limit }}</div>
          </div>
        </template>
      </div>

    </div>

    <!-- Amount Staked -->
    <div v-if="stakesData" class="geoff-bg-section rounded-3 mb-2 p-n">
      <div class="tile-header">
        <div>
          <div>Amount Staked</div>
        </div>
      </div>
      <bar-chart class="py-3" :chart-data="stakesData"></bar-chart>
    </div>

    
  </div>

</template>

<script>
  import { mapGetters } from "vuex";
  // import VueButton from "@/components/UI/VueButton";
  import moment from 'moment'
  import PreLoader from "@/components/UI/PreLoader";
  import BarChart from "@/components/UI/BarChart/BarChart";

  export default {
    name: "UserActivityView",
    components: {
      PreLoader, 
      BarChart
      // VueButton, 
    },
    data:()=>{
      return {
        loading: false,
        selectedRange: 5,
        startPeriod: null,
        endPeriod: null,
        netDeposit: null,
        depositLimit: null,
        winLossData: null,
        stakesData: null,
      }
    },
    computed: {
      ...mapGetters(['CURRENCY']),
      dateRangeString () {
        const end = moment(this.endPeriod)
        const start = moment(this.startPeriod)
        // months
        if (this.selectedRange > 6) {
          return `${start.format('MMM YYYY')} - ${end.format('MMM YYYY')}`
        }
        return `${start.format('DD MMM YYYY')} - ${end.format(' DD MMM YYYY')}`
      }
  
    },
    mounted() {
      this.loadData()
    },
    watch:{
      selectedRange() {
        this.loadData()
      }
    },
    methods:{
      loadData() {
        this.loading = true
        this.API.getUserActivity(this.selectedRange)
          .then(resp => {
            this.stakesData = resp?.stakes
            this.netDeposit = resp?.netDeposit
            this.winLossData = resp.winOrLoss
            this.depositLimit = resp?.depositLimit

            this.startPeriod = resp.startPeriod
            this.endPeriod = resp.endPeriod
          })
          .finally(() => {
            this.loading = false;
          })
      }
    }
  }
</script>

<style scoped lang="scss">

  .user-activity-page {
    min-height: 150px;
  }
  .section-range-selector {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tile-header {
    display: grid;
    grid-template-columns: 1fr auto;
    width: 100%;

    font-size: 1rem;
    
    .note {
      font-size: 0.75rem;
    }
  }

  .net-deposit-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 1rem 0;
    justify-content: center;
    text-align: center;
  }

  .deposit-limit-content {
    display: flex;
    justify-content: space-around;
    padding: 1rem 0;
    text-align: center;
  }

  .label-text {
    font-size: 0.825rem;
  }
  .label-price {
    font-size: 1.5rem;
    line-height: 1;
    text-align: center;
    // &::before {
    //   content: '£';
    // }
    &.label-right {
      text-align: right;
    }
  }
</style>