import { createStore } from 'vuex'
import * as API from "@/http/api"
import betslipStore from "@/store/betslipStore";
import videoStreamStore from "@/store/videoStreamStore";
import {Device} from "@capacitor/device";


export default createStore({
  state: {
    authToken: localStorage.getItem('authToken', null),
    user: {},
    settings: {},
    platform: null,
    isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.innerWidth < 1024
  },
  getters: {
    isMobile(state){
      return state.isMobile
    },
    SETTINGS(state){
      return state.settings
    },
    USER(state){
      return state.user
    },
    CURRENCY(state){
      if(state.user?.paymentCurrency) {
        return state.user.paymentCurrency
      }
      return {
        name: 'Pound Sterling',
        code: 'GBP',
        number: 82,
        symbol: '£',
        main: 'Pound',
        add: 'Pence'
      }
      // else {
        // return {
        //   name: 'Euro',
        //   code: 'EUR',
        //   number: 111,
        //   symbol: '€',
        //   main: 'Euro',
        //   add: 'Cent'
        // }
      // }
    },
    isAuthenticated(state){
      return state.authToken
    }
  },
  mutations: {
    SET_PLATFORM_ON_STATE(state, value){
      state.platform = value
    },
      SET_SETTINGS(state, data) {
      localStorage.setItem('settings',JSON.stringify(data))
      state.settings = data;
    },
    SET_AUTH_TOKEN_ON_STATE(state, token){
      localStorage.setItem('authToken', token)
      state.authToken = token;
    },
    SET_USER_DATA_ON_STATE(state, data){
      for (let key in data){
        state.user[key] = data[key]
      }
      localStorage.setItem('user',JSON.stringify(state.user))
    },
    LOGOUT_ON_STATE(state){
      state.user = {}
      state.authToken = null
      localStorage.removeItem('user')
      localStorage.removeItem('authToken')
    },

  },
  actions: {
    GET_SETTINGS({commit}){
      return new Promise((resolve)=> {
        const promise1 = Device.getInfo().then(info => {
          commit('SET_PLATFORM_ON_STATE', info.platform)
        })
        const promise2 = new Promise(resolve => {
          promise1.then(() => {
              API.getSettings()
              .then(data => {
                commit('SET_SETTINGS', data)
                resolve()
              })
          })
        })
        Promise.all([promise1, promise2]).then(() => resolve())
      })
    },
    async UPDATE_USER_DATA({commit, dispatch}){
      return new Promise((resolve, reject)=>{
        let authData = JSON.parse(localStorage.getItem('userCreds'))
        API.login(authData.username, authData.password, (data)=>{
          if (data.status === '0') {
            dispatch('LOGOUT')
            reject(data.message)
          }
          commit('SET_USER_DATA_ON_STATE', data)
          resolve()
        })
      })
    },
    async LOGIN({commit, dispatch}, authData){
      return new Promise((resolve, reject)=>{
        let username = null
        API.login(authData.username, authData.password, (data)=>{
          if (data.status === '0') {
            dispatch('LOGOUT')
            reject(data.message)
            return
          }
          username = data.cust_Ref
          localStorage.removeItem('realityCheckAt')
          commit('SET_AUTH_TOKEN_ON_STATE', data.token)
          commit('SET_USER_DATA_ON_STATE', data)
          let promise1 = API.getUserDetailsByCustRef(username).then((data)=>{
            console.log('getUserDetailsByCustRef', data)
            const model = {
              ...data.data[0],
              userDetailsLoaded: true
            }
            commit('SET_USER_DATA_ON_STATE', model)
            // commit('SET_USER_DATA_ON_STATE', data.data[0])
            
          })
          let promise2 = API.getUserPreferences(username).then((data)=>{
            if (data.data.length){
              commit('SET_USER_DATA_ON_STATE', {
                  oddsFormat: data.data[0].odds_format,
                  accountStatus: data.data[0].account_status,
                })
            }
            })
          let promise5 = dispatch('BETSLIP_LOGIN', authData)

          let promise3 = new Promise((resolve, reject) => {
            promise5.then(()=> {
              API.getUserInfo(authData)
                .then(res=>{
                  commit('SET_USER_DATA_ON_STATE', {
                      email : res.emailAddress,
                      limit : res.telephoneNumber,
                      workPhone : res.work_Tel,
                      phone : res.mobile,
                      address1 : res.address1,
                      address2 : res.address2,
                      address3 : res.address3,
                      city : res.address4,
                      country : res.country,
                      zip : res.postCode,
                      userInfoLoaded: true,
                      firstName: res.firstName,
                      lastName: res.lastName,
                      isCompliancePopup: res.isCompliancePopup,
                      paymentCurrency: res.paymentCurrency
                    })
                    resolve(res)
                })
                .catch(er => {
                  reject(er)
                })
            })
          });
          

          // get data from directus and create new user in directus if needed (empty)
          // create new user requires AUTH_TOKEN, so should be done after betslip authorization
          let promise4 = new Promise((resolve, reject) => {
            promise5.then(()=> {
              API.getUserDetailFromDirectus()
                .then(res=>{
                  if (res.data.length){
                    commit('SET_USER_DATA_ON_STATE', {direct: res.data[0]})
                    resolve(res.data[0])
                  } else {
                    API.createUserDetails({username: username, status: "published"})
                      .then(res=>{
                        commit('SET_USER_DATA_ON_STATE', {direct: res})
                        resolve(res)
                      })
                      .catch(er => {
                        reject(er)
                      })
                  }
                })
                .catch(er => {
                  reject(er)
                })
            })
          });
          

          // // azure user preferences
          let promise6 = new Promise((resolve, reject) => {
            promise5
              .then(() => {
                API.getUserPreferencesAzure()
                  .then(data => {
                    commit('SET_USER_DATA_ON_STATE', {
                      userPreferencesLoaded: true,
                      preferences:  {
                        carousel: data.carousel,
                        oddFormat: data.oddFormat,
                        sortRacing: data.sortRacing,
                        carouselRegion: data.carouselRegion
                      }
                    })

                    resolve(data)
                  })
                  .catch(er => {
                    reject(er)
                  })
              })
              .catch(er => {
                reject(er)
              })
          });

          Promise.all([promise1, promise2, promise3, promise4, promise5, promise6])
            .then(()=>{
              localStorage.setItem('userCreds', JSON.stringify({
                username:authData.username,
                password:authData.password
              }))
              resolve()
            })
            .catch(err=>reject(err))
        },(error)=>{console.error('!!!!!!!!!', error); reject(error)})
      })
    },

    SET_USER_DATA_LOAD({commit}){
      commit('SET_USER_DATA_ON_STATE', {
        userInfoLoaded: false,
        userDetailsLoaded: false
      })
    },

    SET_AUTH_TOKEN({commit}, token){
      commit('SET_AUTH_TOKEN_ON_STATE', token)
    },
    SET_USER_DATA({commit}, data){
      return new Promise(resolve=>{
        commit('SET_USER_DATA_ON_STATE', data)
        resolve()
      })
    },
    LOGOUT({commit, dispatch}){
      return new Promise(resolve=>{
        localStorage.removeItem('userCreds')
        commit('LOGOUT_ON_STATE')
        dispatch('BETSLIP_LOGOUT')
        resolve()
      })
    }

  },
  modules: {
    betslipStore,
    videoStreamStore
  }
})
