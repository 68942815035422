<template>
  <div class="form-page position-relative" :class="{'p-3': $store.state.isMobile}">
    <div v-if="loading">
      <div class="loading-shade" :class="{'compensate': !$store.state.isMobile}"></div>
      <pre-loader></pre-loader>
    </div>
    <div>
      <div class="header">
        <div class="title">Withdraw to Bank Account - {{ $store.state.user.cust_Ref }}</div>
        <!-- <div>It is your responsibility the details provided are accurate. Geoff Banks Online accept no responsibility for payments made to accounts provided that are incorrect.</div> -->
      </div>
      <hr>
    </div>

    <form @submit.prevent="submitForm" autocomplete="off" novalidate="novalidate">
      <div class="fields-grid">
        <div>
          <div class="field-wr">
            <div class="label">Amount to Withdraw</div>
            <div class="money-container max-width-field">
              <vue-input
                class="money-input"
                name="deposit-limit"
                type="number"
                min="0"
                pattern="\d*"
                v-model="pound"
                :placeholder="CURRENCY.main"
              />
              <vue-input
                class="money-input pence"
                name="deposit-limit"
                type="number"
                min="0"
                pattern="\d*"
                :placeholder="CURRENCY.add"
                v-model="pence"
              />
            </div>
            <!-- <div class="note">minimum withdrawal amount £10</div> -->
          </div>

          <div class="field-wr">
            <div class="label">Account Name/Account Holder</div>
            <div class="name-container max-width-field">
              <vue-input
                class="txt-input"
                name="deposit-limit"
                type="text"
                v-model="accountHolderFirstName"
                placeholder="First Name"
              />
              <vue-input
                class="txt-input"
                name="deposit-limit"
                type="text"
                v-model="accountHolderLastName"
                placeholder="Last Name"
              />
            </div>
          </div>

          <div class="field-wr">
            <div class="label">Use my previous details</div>
            <div class="text-container max-width-field">
              <vue-checkbox 
                class="checkbox-input"
                v-model="isUseMyPreviousDetails"
                description="'terms'"
                name="cbx-agree"
                label-class="w-100"
              >
                <div class="cbx-label">I have previously withdrawn from Geoff Banks and provided my sort code and account number on this form or provided my details during registration.</div>
              </vue-checkbox>
            </div>
            <div  v-if="isUseMyPreviousDetails" class="cbx-label mb-3">We will send your withdrawal to the bank account provided previously. We require your sort code and account number for all first time withdrawals. If you are unsure as to whether this has been done before, then please provide those details again by unchecking this box.</div>
          </div>

        </div>
        <div>
          <div v-if="isUseMyPreviousDetails">
            <div class="field-wr">
              <div class="label">Last 4 Digits of Banks Account Number</div>
              <div class="text-container max-width-field">
                <vue-input
                  class="txt-input input-number-hide-spinner"
                  name="deposit-limit "
                  type="number"
                  v-model="accountNumberLastFour"
                  placeholder="Last 4 Digits of Banks Account Number"
                />
              </div>
            </div>
          </div>

          <div v-else>
            <div class="field-wr">
              <div class="label">Bank Account Number</div>
              <div class="text-container max-width-field">
                <vue-input
                  class="txt-input input-number-hide-spinner"
                  name="deposit-limit "
                  type="number"
                  v-model="accountNumber"
                  placeholder="8 Digit Number"
                />
              </div>
            </div>

            <div class="field-wr">
              <div class="label">Sort Code</div>
              <div class="text-container max-width-field">
                <vue-input
                  class="txt-input input-number-hide-spinner"
                  name="deposit-limit"
                  type="number"
                  v-model="sortCode"
                  placeholder="6 Digit Number"
                />
              </div>
            </div>

            <div class="field-wr">
              <div class="label">IBAN Number <span class="note">(Only required for Non-UK Customers)</span></div>
              <div class="text-container max-width-field">
                <vue-input
                  class="txt-input"
                  name="deposit-limit"
                  type="text"
                  v-model="iban"
                  placeholder="For Accounts outside UK"
                />
              </div>
            </div>
          </div>
          <div class="btn-submit-wr">
            <vue-button  cls="main" class="mt-5XXX mb-2">Withdraw {{ CURRENCY.symbol }}{{ withdrawAmount }}</vue-button>
          </div>
          <div class="mb-2">It is your responsibility the details provided are accurate. Geoff Banks Online accept no responsibility for payments made to accounts provided that are incorrect.</div>
          <div>Change of registered bank details can only be made via support at geoffbanks.bet.</div>

        </div>

      </div>
    </form>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import PreLoader from "@/components/UI/PreLoader";
  import VueInput from "@/components/UI/VueInput";
  import VueButton from "@/components/UI/VueButton";
  import VueCheckbox from "@/components/UI/VueCheckbox";

  export default {
    name: "WithdrawalView",
    components: {
      PreLoader,
      VueInput,
      VueButton,
      VueCheckbox
    },
    data:()=>{
      return{
        loading: false,
        pound: {
          type: Number,
          default: null
        },
        pence: {
          type: Number,
          default: null
        },
        accountHolderFirstName: '',
        accountHolderLastName: '',
        accountNumber: '',
        sortCode: '',
        iban: '',
        isUseMyPreviousDetails: false,
        accountNumberLastFour: '',
      }
    },
    computed: {
      ...mapGetters(['CURRENCY']),
      withdrawAmount() {
        const pound = parseInt(this.pound) || 0
        const pence = parseInt(this.pence) || 0
        const amount = parseFloat(`${pound}.${pence}`)
        return amount.toFixed(2)
      }
    },
    methods: {

      submitForm() {
        // validate
        const pound = parseInt(this.pound) || 0
        const pence = parseInt(this.pence) || 0

        let data = {
          firstName: this.accountHolderFirstName,			          //not null, not empty
          lastName: this.accountHolderLastName,				          //not null, not empty
          userName: this.$store.state.user.cust_Ref,	          //not null, not empty
          pounds: pound,				                                //Range(1-maxValue)
          pence: pence,					                                //Range(0-maxValue)
          isUseMyPreviousDetails: this.isUseMyPreviousDetails,  //bool
          // lastFourBankAccount: this.accountNumberLastFour    //-
          // bankAccountNumber: this.accountNumber,	            //not null, not empty, MinLength(8), MaxLength(8)
          // sortCode: this.sortCode,			                      //not null, not empty, MinLength(6), MaxLength(6)
          // IBANNumber: this.iban                              //-
        }

        const amount = parseFloat(`${data.pounds}.${data.pence}`)
        if(amount < 10 ) {
          this.$toast.warning(`Please, enter valid Amount to Withdraw! Minimum withdrawal amount ${this.CURRENCY.symbol}10.`)
          return
        }

        if (!data.firstName.trim()) {
          this.$toast.warning("Account holder first name is required!")
          return
        }

        if (!data.lastName.trim()) {
          this.$toast.warning("Account holder last name is required!")
          return
        }

        // last 4 digit
        if (data.isUseMyPreviousDetails) {

          data = {
            ...data,
            lastFourBankAccount: this.accountNumberLastFour
          }

          if (!data.lastFourBankAccount) {
            this.$toast.warning("Last 4 Digits of Banks Account Number are required!")
            return
          }
          const lastFour = data.lastFourBankAccount.replace(/[^\d.-]+/g, '');
          if (data.lastFourBankAccount != lastFour) {
            this.$toast.warning("Last 4 Digits of Banks Account Number must contains only digits!")
            return
          }
          if (`${data.lastFourBankAccount}`.length !== 4) {
            this.$toast.warning("Last 4 Digits of Banks Account Number must contains 4 digits!")
            return
          }
        }
        // full form
        else {
          data = {
            ...data,
            bankAccountNumber: this.accountNumber,	 //not null, not empty, MinLength(8), MaxLength(8)
            sortCode: this.sortCode,			           //not null, not empty, MinLength(6), MaxLength(6)
            IBANNumber: this.iban                    //-
          }

          if (!data.bankAccountNumber) {
            this.$toast.warning("Bank account number is required!")
            return
          }
          const digitAccNumber = data.bankAccountNumber.replace(/[^\d.-]+/g, '');


          if (data.bankAccountNumber != digitAccNumber) {
            this.$toast.warning("Bank account must contains only digits!")
            return
          }
          if (`${data.bankAccountNumber}`.length !== 8) {
            this.$toast.warning("Bank account must contains 8 digits!")
            return
          }

          if (!data.sortCode) {
            this.$toast.warning("Sort code number is required!")
            return
          }
          const digitSortNumber = data.sortCode.replace(/[^\d.-]+/g, '');
          if (data.sortCode != digitSortNumber) {
            this.$toast.warning("Sort code must contains only digits!")
            return
          }
          if (`${data.sortCode}`.length !== 6) {
            this.$toast.warning("Sort code must contains 6 digits!")
            return
          }
        }

        this.loading = true;
          this.API.requestWithdrawal(data)
            .then(() => {
              this.$toast.success("Your withdrawal request has been received.<br/>Withdrawals will be in your account within 1-3 working days.")
              this.clearForm()
            })
            .finally(()=>{
              this.loading = false
            })
        
      },

      clearForm() {
        this.accountHolderFirstName = ''
        this.accountHolderLastName = ''
        this.pound = null
        this.pence = null
        this.accountNumber = ''
        this.sortCode = ''
        this.iban = ''
        this.accountNumberLastFour = ''
      }
    }
  }
</script>

<style scoped lang="scss">

.form-page {
  .header {
    .title {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }
  .section {
    margin-bottom: 2rem;
    * {
      margin-bottom: 0.5rem;
    }
  }
  .subheader {
      font-weight: 700;
  }
  .question {
    font-style: italic;
  }
}

.field-wr {
  .label {
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  .note {
    font-size: 0.8rem;
    font-weight: 400;
  }
}

.max-width-field {
  @media screen and (max-width: 639px) {
    max-width: 400px;
  }
}
.text-container {
}
.name-container {
  display: flex;
  align-items: center;
  gap: 1rem;

  .txt-input {
    flex-grow: 1;
  }
}

.money-container {
  display: flex;
  align-items: center;
  gap: 1rem;

  .money-input {
    flex-grow: 1;
    margin: 0 !important;
  }
}

.fields-grid {

  @media screen and (min-width: 640px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
  
}

.btn-submit-wr {
  padding-top: 2rem;
}
.cbx-label {
  font-size: 1rem;
  line-height: 22px;
}
</style>