<template>
  <div class="form-page position-relative" :class="{'p-3': $store.state.isMobile}">
    <!-- <div v-if="loading || !isUserLoaded "> -->
    <div v-if="loading">
      <div class="loading-shade" :class="{'compensate': !$store.state.isMobile}"></div>
      <pre-loader></pre-loader>
    </div>

    <div>
      <div class="header">
        <div class="title">Responsible Gambling Controls with Geoff Banks Online</div>
        <div>You cannot control the events you bet on, but you can control how often using our responsible gambling tools.</div>
      </div>
      <hr/>
    </div>

    <form @submit.prevent="submitForm" autocomplete="off" novalidate="novalidate">
      <div>
        
        <div class="field-wr">
          <div class="label">To deactivate your account or exclude yourself from gambling for a period of time, chose one of the following options:</div>
          <div v-for="(opt) in reponsibleGamblingOptions" :key="opt.value">
            <label>
              <input class="radio geoff-radio" type="radio" :value="opt.value" v-model="model.gamblingOption">
              <span>{{ opt.label }}</span>
            </label>
          </div>
        </div>

        <hr v-if="model.gamblingOption !== null" class="split-line"/>

        <!-- ACCOUNT DEACTIVATION -->
        <div v-if="model.gamblingOption === 0">
          <div class="mb-3">You can choose to decide to close your Geoff Banks Account at any time. This is an easy and popular method to either take a defined pause from gambling, or to simply cease doing business with us. To do this please fill in this simple form.</div>

          <div class="field-wr">
            <div class="label lbl-required">Password:</div>
            <div class="text-container max-width-field">
              <vue-input
                name="city"
                type="password"
                placeholder="Password"
                v-model="model.password"
                update-on-input
              />
            </div>
          </div>

          <div class="field-wr">
            <vue-checkbox 
              class="checkbox-input"
              v-model="model.deactivateConfirm"
              description="'terms'"
              name="cbx-agree"
            >
              <div class="agree-terms lbl-required">I confirm I would like to deactivate my account with Geoff Banks Online</div>
            </vue-checkbox>
          </div>

          <vue-button  class="my-5" :disabled="isSubmitDisabled" :cls="isSubmitDisabled ? 'disable' : 'main'" @click="submitForm">Submit</vue-button>

        </div>

        <!-- TAKE_A_BREAK -->
        <div v-if="model.gamblingOption === 1">

          <div class="mb-3">A Take-A-Break period will block your access to Geoff Banks for a period of your choosing. Select a period from 1 day to 6 weeks. This is an excellent way to take some time away from wagering, removing the ability to reactivate before the period has expired. Once you enter into a Take-A-Break this cannot be removed from your account.</div>

          <div class="field-wr">
            <div class="label lbl-required">Take-a-Break Length:</div>
            <div v-for="(opt) in breakOptions" :key="opt.value">
              <label>
                <input class="radio geoff-radio" type="radio" :value="opt.value" v-model="model.breakLength">
                <span>{{ opt.label }}</span>
              </label>
            </div>
          </div>

          <div class="field-wr">
            <div class="label lbl-required">Reason for closure:</div>
            <div class="text-container max-width-field">
              <vue-textarea
                :maxlength="2001"
                :minlength="10"
                show-characters-left
                name="closureReason"
                type="text"
                placeholder="Reason for closure"
                v-model="model.breakReason"
              />

            </div>
          </div>

          <div class="field-wr">
            <div class="label lbl-required">Password:</div>
            <div class="text-container max-width-field">
              <vue-input
                name="city"
                type="password"
                placeholder="Password"
                v-model="model.password"
                update-on-input
              />
            </div>
          </div>

          <div class="field-wr">
            <vue-checkbox 
              class="checkbox-input"
              v-model="model.breakConfirm"
              description="'terms'"
              name="cbx-agree"
            >
              <div class="agree-terms lbl-required">I accept the Terms & Conditions and understand that I will not be able to reverse this decision or reopen my Geoff Banks Account until my requested exclusion period has expired.</div>
            </vue-checkbox>
          </div>

          <vue-button class="my-5" :disabled="isSubmitDisabled" :cls="isSubmitDisabled ? 'disable' : 'main'"  @click.stop.prevent="openConfirmModal">Submit</vue-button>

          <div class="section">
            <div class="subheader">As a result of Take-A-Break:</div>
            <ul>
              <li>You can no longer bet with Geoff Banks.</li>
              <li>We will do all we can to prevent you from opening another account with us, but also ask that you avoid trying to open another account.</li>
              <li>We will stop all marketing communications within 48 hours of your Take-A-Break being applied.</li>
            </ul>
            <div class="subheader">How can I get my Take-A-Break period removed</div>
            <div>We are unable to remove your Take-A-Break before it expires. Once your Take-A-Break period has expired you can reactivate your account if you wish. If you're reactivating after a Take-A-Break period has expired, there is an industry standard 24-hour cooling off period from when you request your reactivation. After this 24 hours is up, you will receive your account details via email.</div>
          </div>
        
        </div>

        <!-- SELF EXCLUSION -->
        <div v-if="model.gamblingOption === 2">
          <div class="mb-3">Self-Exclusion can block any further activity on your account for 6 months, 1 year, 2 years, 3 years, 4 years or 5 years. You can extend the Self-Exclusion at any time. Once you enter into a Self-Exclusion this cannot be removed from your account.</div>

          <div class="field-wr">
            <div class="label lbl-required">Self Exclusion Length:</div>
            <div v-for="(opt) in selfExclusionOptions" :key="opt.value">
              <label>
                <input class="radio geoff-radio" type="radio" :value="opt.value" v-model="model.exclusionLength">
                <span>{{ opt.label }}</span>
              </label>
            </div>
          </div>

          <div class="field-wr">
            <div class="label lbl-required">Reason for closure:</div>
            <div class="text-container max-width-field">
              <vue-textarea
                :maxlength="200"
                :minlength="10"
                show-characters-left
                name="closureReason"
                type="text"
                placeholder="Reason for closure"
                v-model="model.exclusionReason"
              />
            </div>
          </div>

          <div class="field-wr">
            <div class="label lbl-required">Password:</div>
            <div class="text-container max-width-field">
              <vue-input
                name="city"
                type="password"
                placeholder="Password"
                v-model="model.password"
                update-on-input
              />
            </div>
          </div>

          <div class="field-wr">
            <vue-checkbox 
              class="checkbox-input"
              v-model="model.exclusionConfirm"
              description="'terms'"
              name="cbx-agree"
            >
              <div class="agree-terms lbl-required">I accept the Terms & Conditions and understand that I will not be able to reverse this decision or reopen my Geoff Banks Account until my requested exclusion period has expired.</div>
            </vue-checkbox>
          </div>

          <vue-button class="my-5" :disabled="isSubmitDisabled" :cls="isSubmitDisabled ? 'disable' : 'main'"  @click.stop.prevent="openConfirmModal">Submit</vue-button>

          <div class="section">
            <div class="subheader">As a result of Self Exclusion:</div>
            <ul>
              <li>You can no longer bet with Geoff Banks.</li>
              <li>We will do all we can to prevent you from opening another account with us, but also ask that you avoid trying to open another account.</li>
              <li>We will refund any money left in your account upon applying the Self-Exclusion.</li>
              <li>We will stop all marketing communications within 48 hours of your Self-Exclusion being applied.</li>
            </ul>

            <div class="subheader">How can I get my Self Exclusion period removed?</div>
            <div>We are unable to remove your Self Exclusion before it expires. Once your Self Exclusion period has expired you can reactivate your account if you wish. If you're reactivating after a Self Exclusion period has expired, there is an industry standard 24-hour cooling off period from when you request your reactivation. After this 24 hours is up, you will receive your account details via email.</div>
          </div>
        </div>

        <!-- 'MULTI-OPERATOR SELF EXCLUSION' -->
        <div v-if="model.gamblingOption === 3">
          <div class="section">
            <div>Geoff Banks is part of the Gamstop Multi Operator Self-Exclusion Scheme GAMSTOP.</div>
            <div>GAMSTOP lets you put controls in place to help restrict your online gambling activities.</div>
            <div>Sign up and you will be prevented from using gambling websites and apps run by companies licensed in Great Britain, for a period of your choosing.</div>
            <div>GAMSTOP is a free service for users. GAMSTOP is available for consumers resident within the United Kingdom (Great Britain and Northern Ireland) only.</div>

            <div class="subheader">How To Register</div>
            <div>In order to successfully sign up to GAMSTOP it is essential that you provide them with a number of details that confirm your identity. This will include:</div>
            <div>Your postcode</div>
            <div>Your date of birth</div>
            <div>Your Email address</div>
            <div>It is important to remember that you cannot sign up to GAMSTOP for another person but you can help someone else with the process.</div>

            <div class="subheader">What Happens Afterwards?</div>
            <div>After you sign up, you'll receive a single email summarising all the details of your exclusion from gambling. It can take up to 24 hours for your self-exclusion to become effective. After this period, you will be excluded from gambling with online gambling companies licensed in Great Britain.</div>
            <div>You will be excluded for a period of 6 months, 1 year or 5 years (depending on the option chosen). Once the minimum duration period has elapsed the self-exclusion will remain in force until you have returned to GAMSTOP to ask for it to be removed and gone through the relevant process.</div>
            <div>Once you are registered, it's important to keep your details up to date by telling us if you move house, change your last name or get a new email address. It is your responsibility to ensure that the details gambling companies hold about you are correct and up to date.</div>
            <div>You're the only one that can make a success of your self-exclusion. Visit the support page for more advice.</div>
            <div>Currently a request to self-exclude on GAMSTOP will not automatically remove you from receiving gambling marketing. You can unsubscribe from any emails you receive from gambling companies, and unfollow gambling sites on social media accounts such as Facebook and Twitter in order to reduce the marketing.</div>
            <div>A request to self-exclude on GAMSTOP does not trigger an automatic return of withdrawable funds from those companies that you hold accounts with. You will need to contact the company directly. GAMSTOP is not responsible for returning funds.</div>
          </div>
        </div>

      </div>

    </form>

    <vue-modal ref="confirmModal" class="confirm-password-modal">
      <template v-slot:default>
        <div class="container-confirm mt-5">
          
          <div v-if="model.gamblingOption == 1">I accept the Terms & Conditions and understand that I will not be able to reverse this decision or reopen my Geoff Banks Account until my requested exclusion period has expired.</div>
          <div v-if="model.gamblingOption == 2">I accept the Terms & Conditions and understand that I will not be able to reverse this decision or reopen my Geoff Banks Account until my requested Exclusion Period has expired. In addition I agree Geoff Banks Online will not be liable if I choose to retain the mobile APP and/or keep notifications enabled from the Geoff Banks website.</div>

          <vue-button 
            cls="main" 
            @click="submitForm" 
            class="mt-5 ms-1">I agree</vue-button>
        </div>
      </template>
    </vue-modal>

    <vue-modal ref="successModal" class="success-modal">
      <template v-slot:default>
        <div class="container-confirm mt-5">
          
          <div v-if="model.gamblingOption == 0">Your deactivation request has been received, you will receive confirmation via email shortly.</div>
          <div v-if="model.gamblingOption == 1">Your take-a-break request has been received, you will receive confirmation via email shortly.</div>
          <div v-if="model.gamblingOption == 2">Your self-exclusion request has been received, you will receive confirmation via email shortly.</div>

          <vue-button 
            cls="main" 
            @click="closeSuccess" 
            class="mt-5 ms-1">OK</vue-button>
        </div>
      </template>
    </vue-modal>

  </div>
</template>

<script>
import {useToast} from "vue-toastification";
import { mapGetters } from "vuex";
import PreLoader from "@/components/UI/PreLoader";
import VueCheckbox from "@/components/UI/VueCheckbox";
import VueInput from "@/components/UI/VueInput";
import VueTextarea from "@/components/UI/VueTextarea";
import VueButton from "@/components/UI/VueButton";
import VueModal from "@/components/UI/VueModal";

export default {
  name: "ResponsibleGamblingView",
  components: {
    PreLoader,
    VueCheckbox,
    VueInput,
    VueTextarea,
    VueButton,
    VueModal
  },
  data:()=>{
    return{
      loading: false,
      restrictionType: {
        accountDeactivation: 0,
        takeABreak: 1,
        selfExclusion: 2
      },
      submitModel: {},
      reponsibleGamblingOptions: [
        { value: 0, label: 'Account Deactivation' },
        { value: 1, label: 'Take-a-Break' },
        { value: 2, label: 'Self Exclusion' },
        { value: 3, label: 'Multi-Operator Self Exclusion' }
      ],
      breakOptions: [
        { value: 0, label: '1 Day' },
        { value: 1, label: '1 Week' },
        { value: 2, label: '2 Weeks' },
        { value: 3, label: '4 Weeks' },
        { value: 4, label: '6 Weeks' },
      ],
      selfExclusionOptions: [
        { value: 5, label: '6 Months' },
        { value: 6, label: '1 Year' },
        { value: 7, label: '2 Years' },
        { value: 8, label: '3 Years' },
        { value: 9, label: '4 Years' },
        { value: 10, label: '5 Years' },
      ],
      model: {
        gamblingOption: null,
        
        // deactivate
        deactivateConfirm: false,
        
        // take-a-break
        breakLength: null,
        breakReason: '',
        breakConfirm: false,

        // self-exclusion
        exclusionLength: null,
        exclusionReason: '',
        exclusionConfirm: false,

        // password
        password: null
      }
    }
  },
  computed: {
    ...mapGetters(['USER']),
    isSubmitDisabled(){
      switch(this.model.gamblingOption) {
        case 0: {
          return !this.model.deactivateConfirm || !this.model.password
        }
        case 1: {
          return !this.model.breakConfirm || 
            !this.model.password || 
            !this.model.breakReason || 
            !this.breakOptions.some(el => el.value === this.model?.breakLength)
        }
        case 2: {
          return !this.model.exclusionConfirm || 
            !this.model.password || 
            !this.model.exclusionReason || 
            !this.selfExclusionOptions.some(el => el.value === this.model?.exclusionLength)
        }
        default:
          return true
      }
    },
    modalCofirmAllowed() {
      return this.model.confirmModal && !!this.password
    }
  },
  setup(){
    const toast = useToast()
    return {toast}
  },

  methods: {
    openConfirmModal() {
      this.$refs.confirmModal.open()
    },

    closeSuccess() {
      this.$refs.successModal.close()
      this.clearData()
    },

    clearData() {
      this.model = 
      {
        gamblingOption: null,
        // deactivate
        deactivateConfirm: false,
        // take-a-break
        breakLength: null,
        breakReason: '',
        breakConfirm: false,
        // self-exclusion
        exclusionLength: null,
        exclusionReason: '',
        exclusionConfirm: false,
        // password
        password: null
      }
    },

    submitForm() {
      this.$refs.confirmModal.close()
      this.loading = true;
      let model = {
        RestrictionType: this.model.gamblingOption
      }
      switch (this.model.gamblingOption) {
        case 1:
        model = {
            ...model,
            period: this.model.breakLength,
            reasonForClosure: this.model.breakReason
          }
          break;
        case 2:
        model = {
            ...model,
            period: this.model.exclusionLength,
            reasonForClosure: this.model.exclusionReason
          }
          break;
      }

      // perform login to check password
      this.API.login(this.USER.cust_Ref, this.model.password, 
        (data) =>{
          if (data.status === '0') {
            this.loading = false;
            this.$toast.error('Password is incorrect')
          }
          else {
            this.API.postResponsibleGamblingForm(model)
            .then(resp => {
              console.log('postResponsibleGamblingForm Success', resp)
              this.loading = false;
              // show success message
              this.$refs.successModal.open()
            })
            .catch(er => {
              this.$toast.error('Something went wrong. Please try again later')
              console.log('RESPONSIBLE_GAMBLING - postResponsibleGamblingForm ERROR', er)
              this.loading = false;
            })
          }

        },
        (err) => {
          console.log('RESPONSIBLE_GAMBLING - SUBMIT - LOGIN ERROR', err)
          this.$toast.error('Something went wrong. Please try again later')
          this.loading = false;
        })

    },

  },
}
</script>

<style scoped lang="scss">
// TODO: move to global?
.form-page {
  .header {
    .title {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }
  .section {
    margin-bottom: 2rem;
    * {
      margin-bottom: 0.5rem;
    }
  }
  .subheader {
      font-weight: 700;
  }
  .question {
    font-style: italic;
  }
}

.field-wr {
  margin-bottom: 1rem;
  .label {
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  .radio {
    margin-right: 0.5rem;
  }

}

.split-line {
  border-top: 2px solid var(--geoff-separator);

}

.fields-grid-3 {

  @media screen and (min-width: 640px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
  }
}
.fields-grid-2 {

  @media screen and (min-width: 640px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
}

.debug-bg {
  background-color: orangered;
}

// modal 

.success-modal,
.confirm-password-modal {
  .window{
    max-width: 50vw;
  }
}

.container-confirm {
  margin: 1rem 0;
  max-width: 60vw;
  @media only screen and (max-width: 1024px){
    max-width: unset;
  }
}

.agree-terms {
  line-height: 22px!important;
}
</style>