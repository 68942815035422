<template>
  <div class="mb-2">
    <label v-if="label" class="my-2 fs-14" :for="name">{{ label }}</label>
    <div class="position-relative mb-2">
      <textarea 
        name="name" 
        id="name"
        :cols="cols"
        :rows="rows"
        :required="required"
        :placeholder="placeholder"
        :class="{ 'error': error }"
        :value="modelValue"
        :pattern="pattern"
        :min="min"
        :max="max"
        :minlength="minlength"
        :maxlength="maxlength"
        ref="input"
        @input="$emit('update:modelValue', $event.target.value)"
        @change="$emit('update:modelValue', $event.target.value)"
      ></textarea>
      <div v-if="isShowLimit" class="text-secondary fs-14 text-end" :class="{'text-danger': error}">{{ charactersLeftText }}</div>
    </div>
    <div v-if="description || error" class="text-secondary fs-14 text-end" :class="{'text-danger': error}">{{error?error:description}}</div>
  </div>
</template>

<script>
export default {
  name: "VueTextarea",
  props:{
    name: {
      type:String,
      default: 'textarea'
    },
    cols: {
      type: String,
      default: 'auto'
    },
    rows: {
      type: String,
      default: '4'
    },
    required: {
      type: Boolean,
      default: undefined
    },
    showCharactersLeft: {
      type: Boolean,
      default: false
    },
    error: {
        type: String,
        default: undefined
    },
    description: {
      type:String,
      default: undefined
    },
    label: {
      type:String,
      default: ''
    },
    placeholder: {
      type:String,
      default: undefined
    },
    pattern: {
      type: String,
      default: undefined
    },
    min: {
      type: String,
      default: undefined
    },
    max: {
      type: String,
      default: undefined
    },
    minlength: {
      type: Number,
      default: undefined
    },
    maxlength: {
      type: Number,
      default: undefined
    },

    modelValue: {
      required: true
    }
  },
  computed: {
    isShowLimit() {
      return this.showCharactersLeft && !!this.maxlength
    },
    charactersLeftText() {
      if (!this.isShowLimit) {
        return false
      }
      return ` Characters used : ${this.modelValue?.length} / ${this.maxlength}.`
      
    } 
  }
}
</script>

<style scoped>
textarea{
  width: 100%;
  /* height: 3.125rem; */
  background: var(--geoff-bg-btn-1);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  border: none;
  overflow: hidden;
}
.error{
  border: 2px solid rgba(var(--bs-danger-rgb),1);
}

textarea:active{
  border: none;
}
textarea:focus{
  border: none;
}
textarea:focus-visible{
  outline: none;
}

</style>